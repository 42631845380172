'use client'

import type { ReactNode } from 'react'

import { QueryClientProvider, QueryClient } from '@tanstack/react-query'

const client = new QueryClient()

const ReactQueryProvider = ({
	children,
}: Readonly<{ children: ReactNode }>) => {
	return (
		<QueryClientProvider client={client}>
			{children}
		</QueryClientProvider>
	)
}

export default ReactQueryProvider
