/* eslint-disable @typescript-eslint/no-non-null-assertion */

export const publicConfig = {
	app: {
		host: process.env.NEXT_PUBLIC_APP_HOST!,
		gitCommitSha: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA! || 'development',
		developer: process.env.NEXT_PUBLIC_YOUR_NAME!,
	},
	tailwind: {
		isIndicatorEnabled: process.env.NEXT_PUBLIC_ENABLE_TAILWIND_INDICATOR! === 'true',
	},
	posthog: {
		key: process.env.NEXT_PUBLIC_POSTHOG_KEY!,
		environment: process.env.NEXT_PUBLIC_POSTHOG_ENVIRONMENT!,
	},
	anon: {
		appConfig: {
			environment: process.env.NEXT_PUBLIC_ANON_ENV! as 'local'
				| 'development'
				| 'staging'
				| 'sandbox'
				| 'prod',
			clientId: process.env.NEXT_PUBLIC_ANON_SDKCLIENT_ID!,
			app: 'linkedin',
			company: 'Dunbar',
			companyLogo: 'http://localhost:3000/apple-touch-icon.png',
			chromeExtensionId: process.env.NEXT_PUBLIC_ANON_CHROME_EXTENSION_ID!,
		},
		debug: process.env.DISABLE_ANON_DEBUG !== 'true',
	},
	pusher: {
		key: process.env.NEXT_PUBLIC_PUSHER_KEY!,
		cluster: process.env.NEXT_PUBLIC_PUSHER_CLUSTER!,
	},
}

export const serverConfig = {
	database: {
		url: process.env.DATABASE_URL!,
		readReplicaUrl1: process.env.DATABASE_READ_REPLICA_URL1!,
		type: process.env.DATABASE_TYPE!,
	},
	redis: {
		url: process.env.REDIS_URL!,
	},
	anon: {
		apiKey: process.env.ANON_API_KEY!,
		sdkClientId: process.env.ANON_SDKCLIENT_ID!,
	},
}

/* eslint-enable @typescript-eslint/no-non-null-assertion */
