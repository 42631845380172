'use client'

import type { queueConfig } from '@repo/queue'
import type { z } from 'zod'

import { useAuth } from '@clerk/nextjs'
import Pusher, { type Channel } from 'pusher-js'
import {
	type ReactNode,
	createContext,
	useContext,
	useEffect,
	useState,
} from 'react'

import { publicConfig } from '~/config'

const pusher = new Pusher(publicConfig.pusher.key, {
	cluster: publicConfig.pusher.cluster,
})

const PusherContext = createContext<Channel | null>(null)

export const PusherProvider = ({
	children,
}: Readonly<{
	children: ReactNode
}>) => {
	const [
		channel,
		setChannel,
	] = useState<Channel | null>(null)

	const {
		userId,
	} = useAuth()

	useEffect(() => {
		if (userId) {
			setChannel(pusher.subscribe(userId))
		}

		return () => {
			if (userId) {
				pusher.unsubscribe(userId)
				setChannel(null)
			}
		}
	}, [
		userId,
	])

	if (!userId) {
		return (
			// eslint-disable-next-line react/jsx-no-useless-fragment
			<>
				{children}
			</>
		)
	}

	return (
		<PusherContext.Provider value={channel}>
			{children}
		</PusherContext.Provider>
	)
}

const usePusher = () => {
	return useContext(PusherContext)
}

type Event = z.infer<typeof queueConfig['pusherQueue']['input']>['event']
type EventMap = {
	[E in Event as E['eventName']]: E['data']
}

export const usePusherListener = <EventName extends keyof EventMap>(eventName: EventName, callback: (data: EventMap[EventName]) => Promise<void> | void) => {
	const channel = usePusher()

	useEffect(() => {
		channel?.bind(eventName, callback)

		return () => {
			channel?.unbind(eventName, callback)
		}
	}, [
		channel,
		callback,
		eventName,
	])
}
