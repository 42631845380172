'use client'

import { useUser } from '@clerk/nextjs'
import { VercelToolbar } from '@vercel/toolbar/next'

const StaffToolbar = () => {
	const {
		user,
	} = useUser()

	const isEmployee = user?.primaryEmailAddress?.emailAddress.endsWith('@trydunbar.com') || false

	return isEmployee ? <VercelToolbar /> : null
}

export default StaffToolbar
