'use client'

import {
	QueryClient,
	QueryClientProvider,
	defaultShouldDehydrateQuery,
} from '@tanstack/react-query'
import { httpLink } from '@trpc/client'
import { useState, type ReactNode } from 'react'
import superjson, { serialize, deserialize } from 'superjson'

import { client } from '~/trpc/client'

const getBaseUrl = () => {
	if (typeof document !== 'undefined') {
		return ''
	}

	const vc = process.env.VERCEL_URL
	if (vc) {
		return `https://${vc}`
	}

	return 'http://localhost:3000'
}

const makeQueryClient = () => {
	return new QueryClient({
		defaultOptions: {
			queries: {
				staleTime: 30_000,
			},
			dehydrate: {
				serializeData: serialize,
				shouldDehydrateQuery: (query) => {
					return defaultShouldDehydrateQuery(query) ||
						query.state.status === 'pending'
				},
			},
			hydrate: {
				deserializeData: deserialize,
			},
		},
	})
}

let clientQueryClientSingleton: QueryClient
const getQueryClient = () => {
	if (typeof document === 'undefined') {
		return makeQueryClient()
	}

	// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition,no-return-assign
	return clientQueryClientSingleton ??= makeQueryClient()
}

const TRPCProvider = ({
	children,
}: Readonly<{
	children: ReactNode
}>) => {
	const queryClient = getQueryClient()

	const [
		trpcClient,
	] = useState(() => {
		return client.createClient({
			links: [
				httpLink({
					url: `${getBaseUrl()}/api/trpc`,
					fetch,
					transformer: superjson,
				}),
			],
		})
	})

	return (
		<client.Provider
			queryClient={queryClient}
			client={trpcClient}
		>
			<QueryClientProvider client={queryClient}>
				{children}
			</QueryClientProvider>
		</client.Provider>
	)
}

export default TRPCProvider
